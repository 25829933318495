import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from './index';
// import AUTH_PREFIX_PATH from 'configs/AppConfig';

const PrivateRoute = ({ component: Componenet, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Componenet {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `/auth/login`,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
