import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
//import { Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import PrivateRoute from 'auth/PrivateRoute';
import AdminRoute from 'auth/AdminRoute';
// import Views from 'views';

export const AdminViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* <PrivateRoute path="/" component={Views} /> */}
        {/* <PrivateRoute
          path={`${APP_PREFIX_PATH}/`}
          component={lazy(() => import(`App`))}
        /> */}

        <AdminRoute
          path={`/admin/home`}
          component={lazy(() => import(`./home`))}
        />
        <AdminRoute
          path={`/admin/newsroom`}
          component={lazy(() => import(`./newsroom`))}
        />
        <AdminRoute
          path={`/admin/company`}
          component={lazy(() => import(`./company`))}
        />
        <AdminRoute
          path={`/admin/account`}
          component={lazy(() => import(`./account/setting`))}
        />
        <AdminRoute
          path={`/admin/users`}
          component={lazy(() => import(`./users/manageusers`))}
        />
        <AdminRoute
          path={`/admin/orders/all`}
          component={lazy(() => import(`./orders/all`))}
        />
        <AdminRoute
          path={`/admin/orders/new`}
          component={lazy(() => import(`./orders/new`))}
        />
        <AdminRoute
          path={`/admin/ranktesting`}
          component={lazy(() => import(`./ranking`))}
        />
        <AdminRoute
          path={`/admin/hetneo`}
          component={lazy(() => import(`./hetneo`))}
        />

        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/test`} /> */}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AdminViews);
