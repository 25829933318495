import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
//import { Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import PrivateRoute from 'auth/PrivateRoute';
import CreditsRoute from 'auth/CreditsRoute';
import Views from 'views';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* <PrivateRoute path="/" component={Views} /> */}
        {/* <PrivateRoute
          path={`${APP_PREFIX_PATH}/`}
          component={lazy(() => import(`App`))}
        /> */}
        {/* <Route
          path={`${APP_PREFIX_PATH}/`}
          component={lazy(() => import(`../homepage`))}
        /> */}
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/submit`}
          component={lazy(() => import(`./newsroom/submit`))}
        />
        {/* testing new submit form */}
        {/* <PrivateRoute
          path={`${APP_PREFIX_PATH}/submit2`}
          component={lazy(() => import(`./newsroom/testing2`))}
        /> */}
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/companies`}
          component={lazy(() => import(`./company`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./orders`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/account`}
          component={lazy(() => import(`./account/setting`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/plans`}
          component={lazy(() => import(`./account/setting/Pricing`))}
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/support`}
          component={lazy(() => import(`./account/setting/faq/index`))}
        />

        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/test`} /> */}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
