import {
  DashboardOutlined,
  FormOutlined,
  UnorderedListOutlined,
  UserOutlined,
  BankOutlined,
  HomeOutlined,
  BulbOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { isAuthenticated } from 'auth/index';

let dashBoardNavTree = [
  {
    key: 'Dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'Dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'home',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'home',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'newsroom',
    path: `${APP_PREFIX_PATH}/newsroom`,
    title: 'News Room',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'submit',
        path: `${APP_PREFIX_PATH}/submit`,
        title: 'Submit',
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'orders',
        path: `${APP_PREFIX_PATH}/orders`,
        title: 'Orders',
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'companies',
    path: `${APP_PREFIX_PATH}/companies`,
    title: 'Companies',
    icon: BankOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'Settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'Settings',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'account',
        path: `${APP_PREFIX_PATH}/account`,
        title: 'Account',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'plans',
        path: `${APP_PREFIX_PATH}/plans`,
        title: 'Plans',
        icon: RocketOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'support',
        path: `${APP_PREFIX_PATH}/support`,
        title: 'Support',
        icon: BulbOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

let navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
