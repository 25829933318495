import {
  DashboardOutlined,
  FormOutlined,
  UnorderedListOutlined,
  UserSwitchOutlined,
  UserOutlined,
  BankOutlined,
  SendOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'Dashboard',
    path: `/admin/home`,
    title: 'Dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'home',
        path: `/admin/home`,
        title: 'Home',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'newsroom',
    path: `/admin/newsroom`,
    title: 'News Room',
    icon: SendOutlined,
    breadcrumb: false,
    submenu: [],
  },

  {
    key: 'orders',
    path: `/admin/newsroom`,
    title: 'Orders',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'neworders',
        path: `/admin/orders/new`,
        title: 'New Orders',
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'orders',
        path: `/admin/orders/all`,
        title: 'All Orders',
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'orders',
    path: `/admin/newsroom`,
    title: 'Manage',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'manageusers',
        path: `/admin/users`,
        title: 'Users',
        icon: UserSwitchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'company',
        path: `/admin/company`,
        title: 'Companies',
        icon: BankOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'ranktesting',
    path: `/admin/ranktesting`,
    title: 'Rank Testing',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'hetneo',
    path: `/admin/hetneo`,
    title: 'Hetneo Testing',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },

  {
    key: 'account',
    path: `/admin/account`,
    title: 'Account',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const adminNavigationConfig = [...dashBoardNavTree];

export default adminNavigationConfig;
