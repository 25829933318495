import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'auth/PrivateRoute';
import AdminRoute from 'auth/AdminRoute';
import AdminViews from 'views/admin';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <PrivateRoute path="/app" component={Views} />
            <AdminRoute path="/admin" component={AdminViews} />
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
